/* istanbul ignore file */
import { compile, match } from 'path-to-regexp'

import { ROUTES } from '@constants/routes'

export const getUrl = (name) => {
  const url = ROUTES[name]
  if (!url) throw Error('Could not find URL')
  return url
}

export const createUrl = (name, params) => {
  const url = getUrl(name)
  return compile(url, { encode: encodeURIComponent })(params)
}

export const getUrlWithReturn = (url, returnTo) => {
  const returnToParam = returnTo
    ? `?returnTo=${encodeURIComponent(returnTo)}`
    : ''

  return `${url}${returnToParam}`
}

export const prependModernHost = (url) => {
  return `${process.env.NEXT_PUBLIC_MODERN_HOST}${url}`
}

export const getBrandEditUrl = (id) => {
  return createUrl('brand_list.edit', { id })
}

export const getBrandViewUrl = (id) => {
  return createUrl('brand_list.view', { id })
}

export const getCollectionDiscountsUrl = (id) => {
  return createUrl('collections.discounts', { id })
}

export const getCollectionViewUrl = (id) => {
  return createUrl('collections.view', { id })
}

export const getCollectionEditUrl = (id) => {
  return createUrl('collections.edit.details', { id })
}

export const navigateToCollectionsEdit = (history, id) => {
  const newLocation = createUrl('collections.edit.details', { id })
  history.push(newLocation)
}

export const navigateToCollectionCreate = (history) => {
  const newLocation = createUrl('collections.create')

  history.push(newLocation)
}

export const getCampaignsEditUrl = ({ campaignSlug, countrySlug }) => {
  return createUrl('campaigns.edit.details', {
    campaignSlug,
    countrySlug
  })
}

export const navigateToCampaignsCreate = (history) => {
  const newLocation = createUrl('campaigns.create')

  history.push(newLocation)
}

export const navigateToBrandCreate = (history) => {
  const newLocation = createUrl('brand_list.create')
  history.push(newLocation)
}

export const navigateToBrandItemEdit = (history, id) => {
  const brandEditUrl = createUrl('brand_list.edit', { id })
  history.push(brandEditUrl)
}

export const navigateToBrandList = (history) => {
  const brandListUrl = createUrl('brand_list')
  history.push(brandListUrl)
}

export const navigateToCollectionList = (history) => {
  const collectionListUrl = createUrl('collections')
  history.push(collectionListUrl)
}

export const navigateToDiscountView = (history, id) => {
  const url = createUrl('discounts.view', { id })
  history.push(getUrlWithReturn(url, createUrl('discounts')))
}

export const navigateToDiscountCreate = (history) => {
  const newLocation = createUrl('discounts.create')
  history.push(newLocation)
}

export const navigateToContentItemEdit = (history, id) => {
  const contentEditUrl = createUrl('content.edit', { id })
  history.push(contentEditUrl)
}

export const getProductsViewUrl = (id) => {
  return createUrl('products.view', { id })
}
export const getProductEditUrl = (id) => {
  return createUrl('products.edit', { id })
}

export const navigateToProductEdit = (history, id) => {
  const productEditUrl = createUrl('products.edit', { id })
  history.push(productEditUrl)
}

export const navigateToProductCreate = (history) => {
  const newLocation = createUrl('products.create')

  history.push(newLocation)
}

export const navigateToDiscountEdit = (history, id, countryCode) => {
  const discountEditUrl = createUrl('discounts.edit.details', {
    id,
    countryCode
  })
  history.push(discountEditUrl)
}

export const navigateToEditCodes = (history, id) => {
  const discountsCodesUrl = createUrl('discounts.codes.edit', { id })
  history.push(discountsCodesUrl)
}

export const navigateToPollList = (history) => {
  const newLocation = createUrl('polls')
  history.push(newLocation)
}

export const navigateToPollCreate = (history) => {
  const newLocation = createUrl('polls.create')
  history.push(newLocation)
}

export const getPollEditUrl = (id) => {
  return createUrl('polls.edit', { id })
}

export const navigateToPollEdit = (history, id) => {
  const pollEditUrl = createUrl('polls.edit', { id })
  history.push(pollEditUrl)
}

export const navigateToReviewCreate = (history) => {
  const newLocation = createUrl('reviews.create')
  history.push(newLocation)
}

export const navigateToReviewEdit = (history, id) => {
  const reviewEditUrl = createUrl('reviews.edit', { id })
  history.push(reviewEditUrl)
}

export const navigateToReviewList = (history) => {
  const newLocation = createUrl('reviews')
  history.push(newLocation)
}

export const getReviewEditUrl = (id) => {
  return createUrl('reviews.edit', { id })
}

export const navigateToDiscountVisibility = (history, id, countryCode) => {
  const discountEditVisibilityUrl = createUrl('discounts.edit.visibility', {
    id,
    countryCode
  })
  history.push(discountEditVisibilityUrl)
}

export const navigateToFeaturedList = (history) => {
  const featuredListUrl = createUrl('featured')
  history.push(featuredListUrl)
}

export const navigateToDiscountList = (history) => {
  const DiscountListUrl = createUrl('discounts')
  history.push(DiscountListUrl)
}

export const getDiscountsViewUrl = (id, returnTo) => {
  const url = createUrl('discounts.view', { id })
  return getUrlWithReturn(url, returnTo)
}

export const getDiscountEditUrl = (id, countryCode) => {
  return createUrl('discounts.edit.details', { id, countryCode })
}

export const navigateToFacetsCreateAttribute = (history) => {
  const newLocation = createUrl('facets.create.attribute')
  history.push(newLocation)
}

export const navigateToFacetsCreateValue = (history) => {
  const newLocation = createUrl('facets.create.value')
  history.push(newLocation)
}

export const navigateToCampaignList = (history) => {
  const campaignsListUrl = createUrl('campaigns')
  history.push(campaignsListUrl)
}

export const navigateToCarouselEdit = (history, carouselUid) => {
  const carouselEditUrl = createUrl('carousel.edit', { id: carouselUid })
  history.push(carouselEditUrl)
}

export const navigateToNotificationList = (history) => {
  const notificationListUrl = createUrl('notifications')
  history.push(notificationListUrl)
}

export const isLoginRoute = (router) => !!match(ROUTES.login)(router.pathname)

export const navigateToPromoBlocksItemEdit = (history, blockId, itemId) => {
  const promoBlocksItemEditUrl = createUrl('promo_blocks.item.edit.details', {
    blockId,
    id: itemId
  })
  history.push(promoBlocksItemEditUrl)
}

export const navigateToFeaturedItemEdit = (history, redemptionTypeId) => {
  const newLocation = createUrl('featured.edit', { id: redemptionTypeId })

  history.push(newLocation)
}

export const navigateToFeaturedItemCreate = (history) => {
  const newLocation = createUrl('featured.add')

  history.push(newLocation)
}

export const navigateToCarouselCreate = (history) => {
  const carouselCreate = createUrl('carousel.create')

  history.push(carouselCreate)
}

export const navigateToDiscountEditRedemption = (history, id, countryCode) => {
  const editRedemption = createUrl('discounts.edit.redemption', {
    id,
    countryCode
  })

  history.push(editRedemption)
}

export const navigateToDiscountAdditionalContentEducation = (
  history,
  id,
  countryCode
) => {
  const discountsCodesUrl = createUrl(
    'discounts.edit.additional_content_education',
    {
      id,
      countryCode
    }
  )
  history.push(discountsCodesUrl)
}

export const navigateToCollectionsOfferList = (history, id) => {
  const collectionsOfferCreate = createUrl('collections.discounts', {
    id
  })

  history.push(collectionsOfferCreate)
}

export const getConnectEditDetailsUrl = (id) => {
  return createUrl('connect.edit.details', { id })
}

export const getConnectViewUrl = (id, returnTo) => {
  const url = createUrl('connect.view', { id })
  return getUrlWithReturn(url, returnTo)
}

export const navigateToConnectEditDetails = (history, id) => {
  const url = createUrl('connect.edit.details', { id })
  history.push(url)
}

export const navigateToConnectListView = (history, id) => {
  const url = createUrl('connect.view', { id })
  history.push(getUrlWithReturn(url, createUrl('connect')))
}

export const navigateToConnectEditCustomise = (history, id) => {
  const url = createUrl('connect.edit.customise', { id })
  history.push(url)
}

export const navigateToConnectList = (history) => {
  const promoBlocksItemListUrl = createUrl('connect')
  history.push(promoBlocksItemListUrl)
}

export const navigateToConnectCreate = (history) => {
  const newLocation = createUrl('connect.create')

  history.push(newLocation)
}

export const getPromoGamesEditUrl = (id) => {
  return createUrl('games.edit.details', { id })
}

export const navigateToPromoGamesCreate = (history) => {
  const promoGameCreateUrl = createUrl('games.create')

  history.push(promoGameCreateUrl)
}

export const navigateToConnectEditCopy = (history, id) => {
  history.push(createUrl('connect.edit.copy', { id }))
}

export const navigateToPromoGamesEditContentUrl = (history, id) => {
  const promoGamesEditContentUrl = createUrl('games.edit.content', { id })
  history.push(promoGamesEditContentUrl)
}

export const navigateToPromoGamesList = (history) => {
  const promoGamesListUrl = createUrl('games')

  history.push(promoGamesListUrl)
}

export const getNotificationsViewUrl = (id, returnTo) => {
  const url = createUrl('notifications.view', { id })
  return getUrlWithReturn(url, returnTo)
}

export const navigateToNotificationsCreate = (history) => {
  const notificationsCreateUrl = createUrl('notifications.create')
  history.push(notificationsCreateUrl)
}

export const getNotificationsEditUrl = (id) => {
  return createUrl('notifications.edit', { id })
}

export const navigateToPromoGamesEditContentQuestion = (
  history,
  id,
  questionId
) => {
  const promoGamesEditContentQuestionUrl = createUrl(
    'games.edit.content.question',
    { id, questionId }
  )

  history.push(promoGamesEditContentQuestionUrl)
}

export const getContentViewUrl = (id) => {
  return createUrl('content.view', { id })
}

export const getContentEditUrl = (id) => {
  return createUrl('content.edit', { id })
}

export const navigateToContentCreate = (history) => {
  const newLocation = createUrl('content.create')

  history.push(newLocation)
}

export const navigateToDiscountsCreate = (history, collectionId) => {
  const url = createUrl('collections.discounts.create', { id: collectionId })
  history.push(url)
}

export const navigateToPromoBlocksItemCreate = (
  history,
  blockId,
  promoBlockType
) => {
  const url = createUrl('promo_blocks.item.create', {
    blockId,
    promoBlockType
  })
  history.push(url)
}

export const getPromoBlocksItemEditUrl = (blockId, itemId) => {
  return createUrl('promo_blocks.item.edit.details', {
    blockId,
    id: itemId
  })
}

export const navigateToPromoBlocksCreate = (history) => {
  const newLocation = createUrl('promo_blocks.create')

  history.push(newLocation)
}

export const getPromoBlocksItemListUrl = (id) => {
  return createUrl('promo_blocks.item.list', {
    blockId: id
  })
}

export const getTranslationsViewLanguagesUrl = (project) => {
  return createUrl('translations.languages', { project })
}

export const getTranslationsViewTranslationsUrl = (project) => {
  return createUrl('translations.translations', { project })
}

export const getTranslationsEditUrl = (project) => {
  return createUrl('translations.edit', { project })
}
